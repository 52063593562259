import { ForwardedRef, forwardRef } from 'react';

import { ArrowLeft, Close } from 'components/icons';
import useViewport from 'helpers/useViewport';
import { useTranslation } from 'react-i18next';
import { SELINA_SORT_CODE, accountNumbers } from 'utils/constants';

import useProfile from '../../../helpers/useProfile';
import useProfileByServicingId from '../../../helpers/useProfileByServicingId';

import {
    ArrowButton,
    BodyContent,
    CloseButton,
    ErrorTitle,
    Heading,
    InfoBox,
    InfoData,
    InfoRow,
    InfoTitle,
    ModalContent,
    Title,
} from './Repayment.styles';

interface ClickProps {
    onCloseModal: () => void;
}

const Repayment = forwardRef(
    ({ onCloseModal }: ClickProps, ref: ForwardedRef<HTMLDivElement>) => {
        const { t } = useTranslation('modal');
        const { gteMedium: isDesktop } = useViewport();
        const profile = useProfile();
        const profileByServicingID = useProfileByServicingId();

        const servicingProvider =
            profile.servicingProvider || profileByServicingID.servicingProvider;

        return (
            <ModalContent ref={ref} tabIndex={-1}>
                {isDesktop && <Heading>{t('repayment.heading')}</Heading>}
                <BodyContent>
                    {!servicingProvider ? (
                        <ErrorTitle>{t('repayment.error-message')}</ErrorTitle>
                    ) : (
                        <>
                            <Title>{t('repayment.selina-bank-details')}</Title>
                            <InfoRow>
                                <InfoBox>
                                    <InfoTitle>
                                        {t('repayment.beneficiary')}
                                    </InfoTitle>
                                    <InfoData>
                                        {t('repayment.selina-finance-limited')}
                                    </InfoData>
                                </InfoBox>
                            </InfoRow>
                            <InfoRow>
                                <InfoBox>
                                    <InfoTitle>
                                        {t('repayment.account-number')}
                                    </InfoTitle>
                                    <InfoData>
                                        {accountNumbers[servicingProvider]}
                                    </InfoData>
                                </InfoBox>
                                <InfoBox>
                                    <InfoTitle>
                                        {t('repayment.sort-code')}
                                    </InfoTitle>
                                    <InfoData>{SELINA_SORT_CODE}</InfoData>
                                </InfoBox>
                            </InfoRow>
                        </>
                    )}
                </BodyContent>
                {!isDesktop ? (
                    <ArrowButton onClick={onCloseModal}>
                        <ArrowLeft />
                    </ArrowButton>
                ) : (
                    <CloseButton onClick={onCloseModal}>
                        <Close />
                    </CloseButton>
                )}
            </ModalContent>
        );
    },
);

export default Repayment;

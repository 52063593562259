import styled from '@emotion/styled';
import { LogoSelina } from 'components/icons';
import { grey700, grey950, white } from 'theme/colors';

export const MobileContent = styled.div`
    display: flex;
    align-items: center;
    margin: 6.4rem 1.6rem 2rem;
    color: ${grey950};
`;

export const DesktopContent = styled.div`
    display: flex;
    justify-content: space-between;
    height: var(--desktop-header-height);
    background-color: ${white};

    & > div {
        display: flex;
        align-items: center;

        & > * {
            margin-left: 2.4rem;
        }

        &:last-child {
            & > * {
                margin-left: 0;
                margin-right: 2.4rem;
            }
        }
    }
`;

export const StyledTitle = styled.span`
    width: 100%;
    font-size: 2.4rem;
    line-height: 2.88rem;
`;

export const CenteredTitle = styled(StyledTitle)`
    text-align: center;
`;

export const SelinaIcon = styled(LogoSelina)`
    width: 11.2rem;
    color: ${grey700};
`;

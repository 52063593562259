/* eslint-disable max-len */
import React from 'react';

const LogoSelina: React.FC = (props) => (
    <svg viewBox='0 0 527.36 122.35' fill='currentColor' {...props}>
        <g>
            <path
                fill='currentColor'
                d='M70.52.55C68.75.28,67,.12,65.24,0l47.29,28A61.08,61.08,0,0,0,70.52.55Z'
            />
            <path
                fill='currentColor'
                d='M122.06,55.23,3.5,40.3a62.73,62.73,0,0,0-3,11.59c-.26,1.68-.4,3.36-.52,5L122.33,58C122.28,57.08,122.15,56.16,122.06,55.23Z'
            />
            <path
                fill='currentColor'
                d='M113.61,93.05l-45.2,29.3A61.34,61.34,0,0,0,113.61,93.05Z'
            />
            <path
                fill='currentColor'
                d='M117.24,36.72l-69-35.48a61.05,61.05,0,0,0-18.1,7l89,33.39C118.6,40,118,38.33,117.24,36.72Z'
            />
            <path
                fill='currentColor'
                d='M.28,68.61A61.07,61.07,0,0,0,4.6,85L122.26,65.31c.06-.94.07-1.87.09-2.8Z'
            />
            <path
                fill='currentColor'
                d='M51.26,122,118,84.17c.65-1.65,1.22-3.33,1.74-5l-86.9,36.73A60.86,60.86,0,0,0,51.26,122Z'
            />
            <path
                fill='currentColor'
                d='M22,108.65l99.1-35.21c.18-.89.41-1.76.55-2.67,0-.23,0-.44.07-.67L10.28,95.6A61.55,61.55,0,0,0,22,108.65Z'
            />
            <path
                fill='currentColor'
                d='M120.77,47.23,19.76,16A61.27,61.27,0,0,0,8.64,29.53l112.78,21C121.22,49.41,121,48.31,120.77,47.23Z'
            />
            <path
                fill='currentColor'
                d='M194.81,61.88a28.7,28.7,0,0,0-7.51-3.29,70.28,70.28,0,0,0-9.72-2c-3.81-.56-7-1.12-9.57-1.69a36.23,36.23,0,0,1-6.1-1.78,7.35,7.35,0,0,1-3.2-2.21,4.85,4.85,0,0,1-.93-3,6.21,6.21,0,0,1,3.89-6.1,23.87,23.87,0,0,1,9.91-1.79,21.44,21.44,0,0,1,10.74,2.44q4.27,2.44,4.65,8h12.21q.18-9.76-6.9-15.34t-20.23-5.59a42.24,42.24,0,0,0-14.74,2.25,19.57,19.57,0,0,0-9.06,6.29,15.1,15.1,0,0,0-3.05,9.39,13.48,13.48,0,0,0,2,7.46,16.78,16.78,0,0,0,5.54,5.3,33.33,33.33,0,0,0,8.12,3.53,70.58,70.58,0,0,0,9.76,2.11c4.38.62,7.91,1.24,10.61,1.83A16.5,16.5,0,0,1,187.16,70a4.53,4.53,0,0,1,1.87,3.9,6.92,6.92,0,0,1-1.5,4.5,9.4,9.4,0,0,1-4.59,2.91,25.37,25.37,0,0,1-7.89,1,28.94,28.94,0,0,1-9.48-1.45,17.38,17.38,0,0,1-6.81-4,9.67,9.67,0,0,1-3-5.77H143.13a13.08,13.08,0,0,0,.8,5.26,19.28,19.28,0,0,0,3.33,5.82,26,26,0,0,0,6.1,5.35,32.44,32.44,0,0,0,8.92,3.9,44.11,44.11,0,0,0,11.92,1.45q9.48,0,15.45-2.25a18.33,18.33,0,0,0,8.82-6.34,16.32,16.32,0,0,0,2.86-9.62A15.68,15.68,0,0,0,199.64,67,14,14,0,0,0,194.81,61.88Z'
            />
            <polygon points='224.8 66.15 263.1 66.15 263.1 56.29 224.8 56.29 224.8 41.18 271.55 41.18 271.55 30.76 212.31 30.76 212.31 91.5 271.64 91.5 271.64 81.07 224.8 81.07 224.8 66.15' />
            <polygon points='297 30.76 284.51 30.76 284.51 91.5 337.36 91.5 337.36 81.07 297 81.07 297 30.76' />
            <rect x='350.78' y='30.76' width='12.48' height='60.74' />
            <polygon points='435.17 75.22 390.02 30.76 377.53 30.76 377.53 91.5 390.02 91.5 390.02 47.38 435.17 91.5 447.66 91.5 447.66 30.76 435.17 30.76 435.17 75.22' />
            <path
                fill='currentColor'
                d='M513.75,91.5h13.61L498.54,30.76H485L456.49,91.5H470l6-13.24h31.72Zm-33-23.66,11.08-24.47L503,67.84Z'
            />
        </g>
    </svg>
);

export default LogoSelina;

import { FC, useEffect } from 'react';

import PrivateRoute from 'components/private-route/PrivateRoute';
import useAuth from 'helpers/useAuth';
import Home from 'pages';
import AdminSearch from 'pages/admin';
import Authentication from 'pages/authentication';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import appRoutes from 'utils/routes';

const AppRoutes: FC = () => {
    const { boot } = useIntercom();

    const auth = useAuth();

    useEffect(() => {
        if (auth.authToken) {
            boot();
        }
    }, [boot, auth]);

    return (
        <Switch>
            <Route path={appRoutes.auth.root}>
                <Authentication />
            </Route>
            <PrivateRoute
                allowedGroups={['ServicingAgent']}
                path={appRoutes.admin.root}
            >
                <AdminSearch />
            </PrivateRoute>

            <PrivateRoute path={appRoutes.home.root}>
                <Home />
            </PrivateRoute>
            <Route path={appRoutes.notFound.root}>
                <Redirect to={appRoutes.home.root} />
            </Route>
        </Switch>
    );
};

export default AppRoutes;

import styled from '@emotion/styled';
import { DialogContent as MuiDialogContent } from '@material-ui/core';
import Text from 'components/atoms/text';
import { LogoSelina } from 'components/icons';
import { grey100, white } from 'theme/colors';
import colours from 'theme/colours/v2';

export const Container = styled.div({
    backgroundColor: grey100,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});

export const Header = styled.header({
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: white,
});

export const Logo = styled(LogoSelina)({ width: 112 });

export const Main = styled.main({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 210,
    padding: '0 32px',
});

export const DialogTitle = styled.div({
    backgroundColor: colours.Neutral40,
    padding: 12,
    textAlign: 'center',
});

export const DialogContent = styled(MuiDialogContent)({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 102px 48px',
    textAlign: 'center',
    alignItems: 'center',
});

export const Title = styled(Text)({
    textAlign: 'center',
});

export const Description = styled.div({
    maxWidth: 480,
});

export const CloseButton = styled.button({
    position: 'absolute',
    width: 24,
    top: 14,
    right: 12,
    padding: 4,
    border: 0,
    cursor: 'pointer',
});

export const ServiceIdHelpImg = styled.img({
    marginTop: 34,
});

export const Button = styled.button({
    background: 'none',
    border: 'none',
    margin: '12px 0 32px',
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: colours.Advance100,
});

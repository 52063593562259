import { useGetProfileQuery } from 'services/profile/profile';

import useAuth from './useAuth';
import useQueryParams from './useQueryParams';

const useProfile = () => {
    const { isLoggedIn, authToken, roles } = useAuth();
    const params = useQueryParams();

    const applicationId = params.get('applicationId');

    const userEmail: string | null = localStorage?.user_email;

    const { data, error } = useGetProfileQuery(userEmail as string, {
        skip:
            !isLoggedIn ||
            !authToken ||
            !!applicationId ||
            roles?.includes('ServicingAgent') ||
            !userEmail,
    });

    return {
        applicationId: data?.applicationId || applicationId,
        applicationType: data?.applicationType,
        dateOfBirth: data?.dateOfBirth,
        error,
        firstName: data?.firstName,
        lastName: data?.lastName,
        emailAddress: userEmail,
        servicingProvider: data?.servicingProvider,
    };
};

export default useProfile;

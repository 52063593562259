import { useGetProfileByServicingIdQuery } from '../services/facility/facility';

import useAuth from './useAuth';
import useQueryParams from './useQueryParams';

const useProfileByServicingId = () => {
    const { impersonation, isLoggedIn, authToken } = useAuth();

    const params = useQueryParams();

    const applicationId = params.get('applicationId');

    const servicingId = impersonation ? impersonation.servicingId : '';

    const { data } = useGetProfileByServicingIdQuery(servicingId, {
        skip: !isLoggedIn || !authToken || !servicingId,
    });

    return {
        applicationId: data?.applicationId || applicationId,
        applicationType: data?.applicationType,
        firstName: data?.firstName,
        lastName: data?.lastName,
        servicingProvider: data?.servicingProvider,
    };
};

export default useProfileByServicingId;

import { SERVICING_PROVIDER } from '../services/profile/profile';

export const PEPPER_ACCOUNT_NUMBER = '50039756';
export const PHOEBUS_ACCOUNT_NUMBER = '60039713';

export const SELINA_SORT_CODE = '40-11-77';

export const accountNumbers = {
    [SERVICING_PROVIDER.PEPPER]: PEPPER_ACCOUNT_NUMBER,
    [SERVICING_PROVIDER.PHOEBUS]: PHOEBUS_ACCOUNT_NUMBER,
};
